<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Campaign Error Logs - Detailed</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row listing">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">

                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="time" v-model="filter.start" class="form-control date" placeholder="To"/>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="time" v-model="filter.end" class="form-control date" placeholder="To"/>
                                </div>

                                <div class="form-group mr-1">
                                    <select class="form-control" v-model="filter.type">
                                        <option selected :value="undefined">--Any Type--</option>
                                        <option v-for="item in types" :key="item" :value="item">{{item}}</option>
                                    </select>
                                </div>

                                <div class="form-group mr-1">
                                    <select class="form-control" v-model="filter.provider">
                                        <option selected :value="undefined">--Any Provider--</option>
                                        <option v-for="item in providers" :key="item" :value="item">{{item}}</option>
                                    </select>
                                </div>

                                <button type="button"  @click="load" class="btn searchBtn text-white mr-1" style="background-color: #383838"><i class="fa fa-search" title="Search"></i> <span>Search Errors</span></button>
                                <button type="button" @click="excel"  class="btn btn-success">Export</button>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        height='100%'
                        width='100%'
                        :dataSource="items"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :allowSorting="true"
                        :allowGrouping='true'
                        :allowFiltering="true"
                        :allowResizing='true'
                        :groupSettings='groupSettings'
                        :filterSettings="filterSettings"
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column field='campaign.0.client' headerText='Client'></e-column>
                            <e-column field='provider' headerText='Provider'></e-column>
                            <e-column field='time_frame' headerText='Date Time'></e-column>
                            <!---<e-column field='date' headerText='Date'></e-column>-->

                            <e-column field='type' headerText='Type'></e-column>
                            <e-column field='product.0.Name' headerText='Product'></e-column>
                            <e-column field='message' minWidth='8' width='800' headerText='Message'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert"
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Group,
    Filter,
    Resize
} from "@syncfusion/ej2-vue-grids";
// import VSelect from 'vue-select'
import Vue from "vue";

Vue.use(GridPlugin);

export default {
    name: "errorsDetails",
    props:['user'],
    components:{

    },
    data() {
        return {
            items: [],
            pageNumber:1,
            providers:[],
            perPage:25,
            pageSettings: {pageSize: 25},
            types:['error', 'proxy', 'fail'],
            type:'',
            Dataset: [],
            filter:{
                start: "00:00",
                end: "23:59"
            },
            timeSelector:['12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'],
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            },
            groupSettings:  {
                showDropArea: false,
                disablePageWiseAggregates: true,
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },


        }
    },

    async created() {

        this.loadProviders();
        this.loadDate()
        // this.load()
        this.$root.preloader = false;

    },

    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize
        ]
    },


    methods: {
        excel: async function (){
            console.log('export');
            try
            {
                this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey, sSearch:null};
                this.Dataset = []
                request.filters = this.filter
                let response = await axios.get(`${this.$root.serverUrl}/admin/campaigns/exporterrorlogs`, {params:request,responseType: 'blob'})
                console.log(response);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Campaign Error Details.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.$root.preloader = false;
            }catch(e){
                console.log(e);
                this.$root.preloader = false;
            }
        },
        loadDate: function () {
            //Load the campaign
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            const dateString = [year, month, day].join('-');
            this.filter.from = dateString;
            this.filter.to = dateString;
        },

        load: async function() {

            this.$root.preloader = true;


            let request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
            this.Dataset = []

            request.filters = this.filter

            let response = await axios.get(`${this.$root.serverUrl}/admin/reports/campaign/error-detail`, {params:request})
            let items = response.data.data;
            this.report = [];

            if(!response.data.data){
                this.$root.preloader = false;
                await Swal('Error!!', response.data.error,'error')
            }else{

                console.log({items: items.length});
                for (let i in items) {

                    this.report.push({
                        campaign: items[i].campaign,
                        type:items[i].type,
                        time_frame: new Date(items[i].date).toString().substr(0,24),
                        date: moment(items[i].date).format("hh:mm s"),
                        product: items[i].product,
                        provider:items[i].proxy.provider,
                        message:items[i].message
                    })
                }
                this.items = this.report;
                this.$root.preloader = false;
            }
        },


        loadProviders: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/proxies`, {params:request}).then(function(resp){
                //Store the stats
                this.providers = (resp.data && resp.data.data)? resp.data.data : [];

            }.bind(this));
        },

        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },

        search() {

        },

        views() {

        },

        getTimeFrame(dateTime) {
            const timeFrameMap = [
                '12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'
            ];
            let d = new Date(dateTime);
            return timeFrameMap[d.getHours()];
        },

        exportToExcel:function() {
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.items,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
    }

}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>
